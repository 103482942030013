* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

hr.my-hr {
  background-color: #D97A43;
  margin-bottom: 15px;
}

/* Navbar Styles */
nav {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background-color: #D97A43;
  z-index: 999;
}

.logo h1, a {
  color: #e7e7e7;
  font-size: 35px;
  font-weight: 500;
  text-decoration: none;
}

.nav-bar{
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 45%;
}

.nav-bar li {
  list-style: none;
}

.nav-bar a {
  color: #e7e7e7;
  text-decoration: none;
  letter-spacing: 3px;
  font-size: 15px;
}
/* Mobile Nav */
.mobile-menu div{
  width: 25px;
  height: 2px;
  background-color: #e7e7e7;
  margin: 5px;
}
.mobile-menu {
  display: none;
}

/* View Styles */
section.view-section {
  background-image: url("./assets/style-images/2nd-page-bg.jpg");
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-color: #0367A6;
  width: 100%;
  min-height: 92vh;
}
div.view-header {
  padding-top: 35px;
  padding-left: 10vw;
  color: #D97A43;
}
.view-header h2 {
  font-size: 3em;
}
div.view-container {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin: auto;
  color: #e7e7e7;
  height: fit-content;
  background-color: #0367A6;
  font-size: 1.3em;
  padding: 25px 35px;
}

/* Home Styles */
header.home-header {
  background-image: url("./assets/style-images/Keith-Tharp-port-bg-narrow.jpg");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #0367A6;
  height: 92vh;
  width: 100%;
}

div.main-title {
  color: #e7e7e7;
  padding: 150px 0px 0px 15%;
}

.main-title h3 {
  font-size: 50px;
  font-weight: 500;
}

.main-title h1 {
  font-size: 85px;
  font-weight: 500;
  position: relative;
  z-index: 9;
}
div.byline {
  padding-left: 5px;
  position: relative;
  bottom: 30px;
  background-color: #D97A43;
}

/* About Styles */
div.about-container {
  width: 75%;
  margin: auto;
  color: #e7e7e7;
  height: fit-content;
  background-color: #0367A6;
  font-size: 1.3em;
  padding: 25px 35px;
}
.about-container h2 {
  font-size: 2em;
  color: #D97A43;
}
img.about-photo {
  width: 200px;
  border: solid 2px #e7e7e7;
  border-radius: 100px;
  position: relative;
  left: 35%;
  top: 2vh;
}

/* Contact styles */

ul.contact-list {
  width: 60%;
}

.contact-list li {
  list-style: none;
  margin-bottom: 10px;
}

.contact-list a, span {
  font-size: 1.3em;
  font-weight: 400;
}

img.contact-icon {
  width: 35px;
  margin-right: 25px;
}
img.contact-photo {
  height: 200px;
  border: solid 2px #e7e7e7;
  float: left;
  margin: 3px 30px 30px 0px;
  left: 50%;
}

/* Portfolio Styles */
div.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #e7e7e7;
  height: fit-content;
  font-size: 1.3em;
  padding: 25px 35px;
}
div.view-buffer {
  padding: 125px;
}

/* Card Styles */
div.project-card {
  width: 420px;
  border: 2px solid #e7e7e7;
  background-color: #0367A6;
  padding: 10px;
  margin: 0px 15px 15px 15px;
}
img.card-image {
  width: 400px;
}
img.portfolio-icon {
  width: 20px;
  margin: 5px 10px 5px 0px;
}
.project-card a {
  font-size: 1.5em;
}
.project-card a:hover {
  color: #D97A43;
}

/* Footer styles */
section.footer-section {
  margin-top: 10px;
  padding: 3px;
  background-color: #D97A43;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Small screen styles */

@media only screen and (min-width: 1100px) {
  /* Home styles */
  div.main-title {
  top: 200px;
  }
  header {
    background-image: url("./assets/style-images/Keith-Tharp-port-bg.jpg");
  }

  section.view-section {
    padding-top: 100px;
  }
}

a.resume {
  display: block;
  font-size: 18;
  font-weight: 400;
  text-decoration: underline;
  color: #D97A43;
}

/* 
!!!!!!!!!!!!!!!
!!! MOBILE !!!!
!!!!!!!!!!!!!!!
*/
@media only screen and (max-width: 640px) {
  body {
    overflow-x: hidden;
    padding-bottom: 30px;
  }
  /* Nav styles */
  .nav-bar {
    position: absolute;
    right: 0px;
    top: 8vh;
    height: 0px;
    background-color: #D97A43;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* transform: translateX(100%);
    transition: transform .5s ease-in; */
    transition: height 500ms ease-in;
  }

  .nav-bar li {
    font-size: 0px;
    transition: font-size 500ms ease-in;
  }

  .mobile-menu {
    cursor: pointer;
    display: block;
  }

  .mobile-menu-display {
    height: 225px;
  }
  .mobile-menu-display li {
    font-size: 1em;
  }

  /* View Styles */
  div.view-container {
    width: 90%;
    padding: 15px 15px;
    justify-content: center;
  }

  section.view-section {
    padding-top: 25px;
  }

  /* Home styles */
  div.main-title {
    padding: 150px 0px 0px 10%;
  }

  .main-title h1 {
    font-size: 14vw;
  }

  .main-title h3 {
    font-size: 8vw;
  }

  header {
    background-image: url("./assets/style-images/Keith-Tharp-port-bg-mob.jpg");
  }

  div.byline {
    bottom: 20px;
  }

  div.portfolio-container {
    width: 100%;
  }
  /* Card Styles */
  div.project-card {
    width: 98%;
    border: 2px solid #e7e7e7;
    background-color: #0367A6;
    padding: 10px;
    margin: 0px 15px 15px 15px;
  }
  img.card-image {
    width: 90%;
  }
  /* Contact Styles */
  .contact-list a, span {
    font-size: .9em;
    font-weight: 400;
  }
  
  img.contact-icon {
    width: 25px;
    margin-right: 10px;
  }

}

@media only screen and (max-width: 400px) {
  div.byline {
    bottom: 15px;
  }
}

